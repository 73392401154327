import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'download',
        meta: {
            title: 'APP下载'
        },
        component: () => import('../view/download/dwPage.vue')
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes
})
   
export default router