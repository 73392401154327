import { createApp } from 'vue'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import { showToast } from 'vant';
import 'amfe-flexible'
import Vant from 'vant' // 组件库
const app = createApp(App)
app.config.globalProperties.$toast = showToast;
app.use(router).use(Vant).mount('#app')

